/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';
/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/docs/ts/latest/guide/browser-support.html
 */

/*
 * BROWSER POLYFILLS
 */

// IE9, IE10 and IE11 requires all of the following polyfills.
// import 'core-js';
// import 'core-js/es/array';
// import 'core-js/es/object';

// Evergreen browsers require these.
// Used for reflect-metadata in JIT. If you use AOT (and only Angular decorators), you can remove.
// import 'core-js/es/reflect';

// This check is added not load polyfills for webex app
const value = window.localStorage.getItem('cbma_webex');
if (!value) {
  require('core-js');
  require('core-js/es/array');
  require('core-js/es/object');
  require('core-js/es/reflect');
}

// Required to support Web Animations `@angular/platform-browser/animations`.

// import 'element-remove';
/*
* in IE/Edge developer tools, the addEventListener will also be wrapped by zone.js
* with the following flag, it will bypass `zone.js` patch for IE/Edge
*/
// (window as any).__Zone_enable_cross_context_check = true;

// Zone JS is required by Angular itself.
import 'zone.js' // Included with Angular CLI.

/*
 * APPLICATION IMPORTS
 */

// Date, currency, decimal and percent pipes.
// Needed for: All but Chrome, Firefox, Edge, IE11 and Safari 10
// import 'intl';  // Run `npm install --save intl`.

/*
 * Need to import at least one locale-data with intl.
 */
// import 'intl/locale-data/jsonp/en';

import { TextDecoder, TextEncoder } from 'text-encoding-shim';
import 'webcrypto-shim';
(window as any).global = window;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).TextEncoder = TextEncoder;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).TextDecoder = TextDecoder;

// Loading Stylesheets
const stylesheetArray = ['assets/styles/blueprint/components/spinner/cssSpinner.css'];
const getStylesheet = (url: string) => {
  const styleEle = document.createElement('link');
  styleEle.rel = 'stylesheet';
  styleEle.href = url;
  document.getElementsByTagName('head')[0].appendChild(styleEle);
};
stylesheetArray.forEach((element) => {
  getStylesheet(element);
});

